import React from 'react'

const IconBirthdayCat = (props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      className="birthday-cat"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 500 500"
      aria-labelledby="title"
    >
      <title id="title">Cat at a birthday-party</title>
      <path
        fill="#507431"
        d="M359.36,433.17a1.63,1.63,0,0,1-1.26,1.93h0a1.63,1.63,0,0,1-1.93-1.26l-10.22-48.69a1.63,1.63,0,0,1,1.26-1.93h0a1.63,1.63,0,0,1,1.93,1.26Z"
      />{' '}
      <path
        fill="#507431"
        d="M349.87,429.9a1.63,1.63,0,0,1-1.26,1.93h0a1.63,1.63,0,0,1-1.93-1.26l-10.22-48.69a1.63,1.63,0,0,1,1.26-1.93h0a1.63,1.63,0,0,1,1.93,1.26Z"
      />
      <path
        fill="#799f4e"
        d="M347.38,432a1.63,1.63,0,0,1-1.26,1.93h0a1.63,1.63,0,0,1-1.93-1.26L334,384a1.63,1.63,0,0,1,1.26-1.93h0a1.63,1.63,0,0,1,1.93,1.26Z"
      />{' '}
      <path
        fill="#799f4e"
        d="M362.2,435.32a1.63,1.63,0,0,1-1.26,1.93h0A1.63,1.63,0,0,1,359,436L348.78,387.3a1.63,1.63,0,0,1,1.26-1.93h0a1.63,1.63,0,0,1,1.93,1.26Z"
      />{' '}
      <path
        fill="#799f4e"
        d="M353.74,432.35a1.63,1.63,0,0,1-1.26,1.93h0a1.63,1.63,0,0,1-1.93-1.26l-10.22-48.69a1.63,1.63,0,0,1,1.26-1.93h0a1.63,1.63,0,0,1,1.93,1.26Z"
      />{' '}
      <path
        fill="#92b04c"
        d="M364,430.56a1.63,1.63,0,0,1-1.26,1.93h0a1.63,1.63,0,0,1-1.93-1.26L350.6,382.54a1.63,1.63,0,0,1,1.26-1.93h0a1.63,1.63,0,0,1,1.93,1.26Z"
      />{' '}
      <path
        fill="#92b04c"
        d="M355.93,432.48a1.63,1.63,0,0,1-1.26,1.93h0a1.63,1.63,0,0,1-1.93-1.26l-10.22-48.69a1.63,1.63,0,0,1,1.26-1.93h0a1.63,1.63,0,0,1,1.93,1.26Z"
      />
      <polygon
        fill="#cc0515"
        points="330.6 437.58 326.98 428.99 317.81 430.37 342.14 387.2 354.93 394.41 330.6 437.58"
      />
      <polygon
        fill="#cc0515"
        points="386.8 428.09 386.4 418.77 395.27 416.09 354.78 387.52 346.32 399.52 386.8 428.09"
      />
      <polygon
        fill="#cc0515"
        points="333.24 390.67 341.04 407.06 357.88 405.05 364.27 379.97 333.24 390.67"
      />{' '}
      <path
        fill="#c6acd2"
        d="M404.25,225.36a8.9,8.9,0,1,0-13.19-11.54A8.9,8.9,0,1,0,373.79,217a8.9,8.9,0,1,0-11.87,13,8.91,8.91,0,1,0,4.7,16.66,8.91,8.91,0,1,0,11.78,12.69,8.9,8.9,0,1,0,17.26-3.38A8.9,8.9,0,1,0,407,242.61a8.9,8.9,0,1,0-2.7-17.26Z"
      />
      <circle
        fill="#9d89a7"
        cx="385.16"
        cy="237.13"
        r="8.14"
        transform="translate(-66.78 219.01) rotate(-29.32)"
      />{' '}
      <path
        fill="#009f57"
        d="M407.89,224.21q0-.6,0-1.19-4.33.37-8.72,1.05a131.45,131.45,0,0,1,11.57-12.43q5.08-.16,10,.09.07-.6.15-1.19-4.36-.23-8.84-.15a131.91,131.91,0,0,1,11.08-9.11l-.68-.93a132.63,132.63,0,0,0-11.18,9.19q.48-4.47,1.32-9h-1.27q-.94,5.19-1.41,10.28a132.84,132.84,0,0,0-11.67,12.54q-.18-4.46,0-9.05l-1.25.17q-.15,5.23.13,10.31a133.13,133.13,0,0,0-9.7,14q-.82-4.38-1.33-8.92l-1.21.34q.61,5.18,1.61,10.15a133.07,133.07,0,0,0-7.62,15.15q-1.43-4.22-2.58-8.64l-1.15.51q1.33,5,3,9.82a132.9,132.9,0,0,0-5.42,16.11q-2-4-3.77-8.21l-1.07.67q2,4.81,4.37,9.32a132.92,132.92,0,0,0-3.1,16.83q-2.55-3.69-4.89-7.65l-1,.82q2.68,4.5,5.64,8.67a133,133,0,0,0-.58,19.79l1.15-.05a131.94,131.94,0,0,1,.57-19.62q3.75-3.43,7.66-6.44l-.66-1q-3.45,2.64-6.79,5.6a131.77,131.77,0,0,1,3.07-16.68q4.19-2.83,8.48-5.22l-.52-1.07q-3.78,2.1-7.5,4.53a131.92,131.92,0,0,1,5.37-16q4.54-2.19,9.12-4l-.36-1.13q-4,1.54-8.06,3.41a132,132,0,0,1,7.55-15q4.8-1.53,9.59-2.63-.1-.58-.2-1.17-4.22,1-8.46,2.25A131.8,131.8,0,0,1,398,225.49Q403,224.64,407.89,224.21Z"
      />{' '}
      <path
        fill="#fff"
        d="M379.82,288a1.38,1.38,0,0,0,.47-1.82,1.25,1.25,0,0,0-.86-.64,1.41,1.41,0,0,0-.06-1.16,1.22,1.22,0,0,0-1.73-.5,1.42,1.42,0,0,0-.62,1.57l0,0a1.43,1.43,0,0,0-.48,1.87,1.22,1.22,0,0,0,1.74.45h0A1.22,1.22,0,0,0,379.82,288Z"
      />{' '}
      <path
        fill="#fff"
        d="M361.17,285a1.75,1.75,0,0,1,.2-2.16,1.19,1.19,0,0,1,1-.37,1.73,1.73,0,0,1,.5-1.21,1.14,1.14,0,0,1,1.8.15,1.71,1.71,0,0,1,0,1.85l0,0a1.71,1.71,0,0,1-.2,2.11,1.14,1.14,0,0,1-1.8-.1h0A1.11,1.11,0,0,1,361.17,285Z"
      />{' '}
      <path
        fill="#fff"
        d="M383.75,271.18a1.37,1.37,0,0,0,.72-1.73,1.26,1.26,0,0,0-.77-.75,1.39,1.39,0,0,0,.1-1.15,1.23,1.23,0,0,0-1.64-.74,1.4,1.4,0,0,0-.83,1.46h0a1.41,1.41,0,0,0-.73,1.77,1.22,1.22,0,0,0,1.66.69h0A1.23,1.23,0,0,0,383.75,271.18Z"
      />{' '}
      <path
        fill="#fff"
        d="M365.7,265.6a1.73,1.73,0,0,1,.5-2.1,1.19,1.19,0,0,1,1.08-.22,1.7,1.7,0,0,1,.66-1.12,1.14,1.14,0,0,1,1.76.4,1.69,1.69,0,0,1-.27,1.82l0,0a1.69,1.69,0,0,1-.48,2,1.15,1.15,0,0,1-1.77-.35h0A1.12,1.12,0,0,1,365.7,265.6Z"
      />{' '}
      <path
        fill="#fff"
        d="M390.07,254.91a1.36,1.36,0,0,0,1-1.6,1.26,1.26,0,0,0-.65-.85,1.39,1.39,0,0,0,.26-1.12,1.23,1.23,0,0,0-1.52-1,1.4,1.4,0,0,0-1,1.33h0a1.41,1.41,0,0,0-1,1.65,1.22,1.22,0,0,0,1.55.92h0A1.23,1.23,0,0,0,390.07,254.91Z"
      />{' '}
      <path
        fill="#fff"
        d="M373,246.84a1.72,1.72,0,0,1,.79-2,1.19,1.19,0,0,1,1.1-.06,1.7,1.7,0,0,1,.81-1,1.14,1.14,0,0,1,1.68.64,1.68,1.68,0,0,1-.53,1.76v0a1.68,1.68,0,0,1-.77,2,1.15,1.15,0,0,1-1.7-.6h0A1.12,1.12,0,0,1,373,246.84Z"
      />{' '}
      <path
        fill="#fff"
        d="M398.58,239.8a1.36,1.36,0,0,0,1.17-1.46,1.26,1.26,0,0,0-.53-.93,1.39,1.39,0,0,0,.42-1.07,1.23,1.23,0,0,0-1.37-1.17,1.4,1.4,0,0,0-1.2,1.17h0a1.41,1.41,0,0,0-1.2,1.5,1.22,1.22,0,0,0,1.4,1.13h0A1.23,1.23,0,0,0,398.58,239.8Z"
      />{' '}
      <path
        fill="#fff"
        d="M382.81,229.4a1.73,1.73,0,0,1,1.06-1.87,1.19,1.19,0,0,1,1.1.09,1.71,1.71,0,0,1,.95-.89,1.14,1.14,0,0,1,1.58.88,1.69,1.69,0,0,1-.77,1.67v0a1.68,1.68,0,0,1-1,1.83,1.15,1.15,0,0,1-1.6-.83h0A1.12,1.12,0,0,1,382.81,229.4Z"
      />{' '}
      <path
        fill="#fff"
        d="M409.05,226.11a1.38,1.38,0,0,0,1.37-1.29,1.25,1.25,0,0,0-.39-1,1.41,1.41,0,0,0,.57-1,1.22,1.22,0,0,0-1.19-1.35,1.42,1.42,0,0,0-1.36,1h0a1.43,1.43,0,0,0-1.41,1.33,1.22,1.22,0,0,0,1.23,1.31h0A1.22,1.22,0,0,0,409.05,226.11Z"
      />{' '}
      <path
        fill="#fff"
        d="M394.89,213.6a1.75,1.75,0,0,1,1.33-1.72,1.19,1.19,0,0,1,1.08.24,1.73,1.73,0,0,1,1.07-.76,1.14,1.14,0,0,1,1.44,1.09,1.71,1.71,0,0,1-1,1.56s0,0,0,0a1.7,1.7,0,0,1-1.29,1.68,1.14,1.14,0,0,1-1.47-1h0A1.11,1.11,0,0,1,394.89,213.6Z"
      />{' '}
      <path
        fill="#fff"
        d="M421.6,213.8a1.41,1.41,0,0,0,1.56-1.09,1.24,1.24,0,0,0-.24-1,1.44,1.44,0,0,0,.72-.93,1.21,1.21,0,0,0-1-1.51,1.45,1.45,0,0,0-1.51.81h0a1.46,1.46,0,0,0-1.6,1.13,1.21,1.21,0,0,0,1,1.48h0A1.21,1.21,0,0,0,421.6,213.8Z"
      />{' '}
      <path
        fill="#fff"
        d="M409.36,199.41a1.79,1.79,0,0,1,1.58-1.53,1.18,1.18,0,0,1,1,.39,1.78,1.78,0,0,1,1.18-.61,1.13,1.13,0,0,1,1.27,1.28,1.75,1.75,0,0,1-1.24,1.42s0,0,0,0a1.74,1.74,0,0,1-1.54,1.49,1.13,1.13,0,0,1-1.31-1.24h0A1.1,1.1,0,0,1,409.36,199.41Z"
      />{' '}
      <path
        fill="#fff"
        d="M425.35,201.72a1.38,1.38,0,0,0,.46-1.87,1.19,1.19,0,0,0-1-.51,1.23,1.23,0,0,0-.11-1.13,1.38,1.38,0,0,0-1.94-.2,1.45,1.45,0,0,0-.61,1.64l0,0a1.29,1.29,0,1,0,1.48,2.07h0A1.43,1.43,0,0,0,425.35,201.72Z"
      />{' '}
      <path
        fill="#009f57"
        d="M250.68,231.66q-.1-.59-.22-1.17,4.31-.51,8.75-.72a131.46,131.46,0,0,0-13.83-9.85q-5,.86-9.81,2.1l-.39-1.14q4.23-1.11,8.63-1.93a131.72,131.72,0,0,0-12.69-6.7l.48-1a132.76,132.76,0,0,1,12.8,6.76q-1.37-4.28-3.11-8.56l1.25-.26q2,4.9,3.45,9.79a132.91,132.91,0,0,1,14,9.94q-.72-4.41-1.79-8.87l1.26-.08q1.2,5.1,1.95,10.13a133,133,0,0,1,12.31,11.73q-.08-4.45-.49-9l1.26.09q.45,5.2.47,10.27a133,133,0,0,1,10.51,13.31q.55-4.42.79-9l1.23.27q-.29,5.2-1,10.22A132.84,132.84,0,0,1,295,272.63q1.16-4.31,2-8.8l1.18.44q-1,5.12-2.41,10a132.8,132.8,0,0,1,6.42,15.86q1.75-4.13,3.25-8.47l1.11.61q-1.72,4.95-3.78,9.63a133,133,0,0,1,4.55,19.27l-1.14.18a132,132,0,0,0-4.51-19.1q-4.36-2.61-8.8-4.77l.45-1.11q3.91,1.89,7.78,4.12a131.76,131.76,0,0,0-6.37-15.72q-4.67-1.93-9.35-3.41l.29-1.15q4.13,1.29,8.26,2.93a131.92,131.92,0,0,0-8.47-14.56q-4.89-1.24-9.73-2c0-.39.09-.79.13-1.18q4.27.69,8.58,1.72a132,132,0,0,0-10.42-13.2q-5-.54-9.92-.65,0-.59,0-1.19,4.32.09,8.74.5a131.81,131.81,0,0,0-12.21-11.63Q255.58,231.09,250.68,231.66Z"
      />{' '}
      <path
        fill="#fff"
        d="M291,288.46a1.38,1.38,0,0,1-.83-1.69,1.26,1.26,0,0,1,.72-.8,1.41,1.41,0,0,1-.17-1.15,1.22,1.22,0,0,1,1.59-.84,1.42,1.42,0,0,1,.92,1.42h0a1.43,1.43,0,0,1,.85,1.74,1.22,1.22,0,0,1-1.62.79h0A1.22,1.22,0,0,1,291,288.46Z"
      />{' '}
      <path
        fill="#fff"
        d="M308.67,281.77a1.75,1.75,0,0,0-.63-2.08,1.19,1.19,0,0,0-1.1-.15,1.73,1.73,0,0,0-.73-1.09,1.14,1.14,0,0,0-1.73.51,1.71,1.71,0,0,0,.39,1.81l0,0a1.71,1.71,0,0,0,.62,2,1.14,1.14,0,0,0,1.74-.46h0A1.11,1.11,0,0,0,308.67,281.77Z"
      />{' '}
      <path
        fill="#fff"
        d="M283.78,272.81a1.37,1.37,0,0,1-1-1.55,1.26,1.26,0,0,1,.6-.89,1.39,1.39,0,0,1-.33-1.1,1.23,1.23,0,0,1,1.46-1.05,1.4,1.4,0,0,1,1.1,1.26h0a1.41,1.41,0,0,1,1.07,1.59,1.22,1.22,0,0,1-1.49,1h0A1.23,1.23,0,0,1,283.78,272.81Z"
      />{' '}
      <path
        fill="#fff"
        d="M300.33,263.71a1.73,1.73,0,0,0-.91-2,1.19,1.19,0,0,0-1.1,0,1.71,1.71,0,0,0-.87-1,1.14,1.14,0,0,0-1.64.75,1.69,1.69,0,0,0,.63,1.73v0a1.68,1.68,0,0,0,.89,1.91,1.15,1.15,0,0,0,1.66-.7h0A1.12,1.12,0,0,0,300.33,263.71Z"
      />{' '}
      <path
        fill="#fff"
        d="M274.31,258.15a1.36,1.36,0,0,1-1.25-1.38,1.26,1.26,0,0,1,.47-1,1.39,1.39,0,0,1-.48-1,1.23,1.23,0,0,1,1.3-1.25,1.4,1.4,0,0,1,1.27,1.09h0a1.41,1.41,0,0,1,1.29,1.42,1.22,1.22,0,0,1-1.33,1.21h0A1.23,1.23,0,0,1,274.31,258.15Z"
      />{' '}
      <path
        fill="#fff"
        d="M289.41,246.8a1.72,1.72,0,0,0-1.17-1.8,1.19,1.19,0,0,0-1.09.16,1.7,1.7,0,0,0-1-.83,1.14,1.14,0,0,0-1.52,1,1.69,1.69,0,0,0,.87,1.62v0a1.68,1.68,0,0,0,1.15,1.76,1.15,1.15,0,0,0,1.54-.93h0A1.12,1.12,0,0,0,289.41,246.8Z"
      />{' '}
      <path
        fill="#fff"
        d="M262.94,245a1.36,1.36,0,0,1-1.44-1.19,1.26,1.26,0,0,1,.33-1,1.39,1.39,0,0,1-.62-1,1.23,1.23,0,0,1,1.11-1.42,1.4,1.4,0,0,1,1.41.91h0a1.41,1.41,0,0,1,1.48,1.23,1.22,1.22,0,0,1-1.15,1.39h0A1.23,1.23,0,0,1,262.94,245Z"
      />{' '}
      <path
        fill="#fff"
        d="M276.29,231.69a1.73,1.73,0,0,0-1.42-1.62,1.19,1.19,0,0,0-1.06.31,1.71,1.71,0,0,0-1.11-.68,1.14,1.14,0,0,0-1.37,1.17,1.69,1.69,0,0,0,1.09,1.48s0,0,0,0a1.68,1.68,0,0,0,1.38,1.58,1.15,1.15,0,0,0,1.4-1.14h0A1.12,1.12,0,0,0,276.29,231.69Z"
      />{' '}
      <path
        fill="#fff"
        d="M249.93,233.75a1.38,1.38,0,0,1-1.6-1,1.25,1.25,0,0,1,.18-1.06,1.41,1.41,0,0,1-.76-.88,1.22,1.22,0,0,1,.9-1.56,1.42,1.42,0,0,1,1.54.71h0a1.43,1.43,0,0,1,1.64,1,1.22,1.22,0,0,1-.94,1.53h0A1.22,1.22,0,0,1,249.93,233.75Z"
      />{' '}
      <path
        fill="#fff"
        d="M261.28,218.65a1.75,1.75,0,0,0-1.64-1.42,1.19,1.19,0,0,0-1,.46,1.73,1.73,0,0,0-1.2-.53,1.14,1.14,0,0,0-1.19,1.35,1.71,1.71,0,0,0,1.3,1.33v0a1.7,1.7,0,0,0,1.6,1.38,1.14,1.14,0,0,0,1.23-1.32h0A1.11,1.11,0,0,0,261.28,218.65Z"
      />{' '}
      <path
        fill="#fff"
        d="M235.16,224.21a1.41,1.41,0,0,1-1.75-.76,1.25,1.25,0,0,1,0-1.08,1.44,1.44,0,0,1-.89-.77,1.21,1.21,0,0,1,.66-1.67,1.45,1.45,0,0,1,1.64.49h0a1.46,1.46,0,0,1,1.79.78,1.21,1.21,0,0,1-.71,1.65h0A1.21,1.21,0,0,1,235.16,224.21Z"
      />{' '}
      <path
        fill="#fff"
        d="M244.25,207.65a1.79,1.79,0,0,0-1.85-1.18,1.18,1.18,0,0,0-.94.59,1.78,1.78,0,0,0-1.28-.36,1.13,1.13,0,0,0-1,1.51,1.75,1.75,0,0,0,1.5,1.14v0a1.74,1.74,0,0,0,1.81,1.15,1.13,1.13,0,0,0,1-1.48h0A1.1,1.1,0,0,0,244.25,207.65Z"
      />{' '}
      <path
        fill="#fff"
        d="M229.05,213.13a1.38,1.38,0,0,1-.83-1.74,1.19,1.19,0,0,1,.84-.69,1.23,1.23,0,0,1-.12-1.13,1.37,1.37,0,0,1,1.86-.59,1.45,1.45,0,0,1,.93,1.48h0a1.28,1.28,0,1,1-1,2.33h0A1.43,1.43,0,0,1,229.05,213.13Z"
      />{' '}
      <path
        fill="#009f57"
        d="M335.44,201.48l-.33-1.15q-4.08,1.48-8.15,3.28a131.62,131.62,0,0,1,7.95-15q4.86-1.47,9.71-2.52-.08-.59-.16-1.19-4.27.91-8.57,2.15a131.62,131.62,0,0,1,8.33-11.68l-.9-.72a132.92,132.92,0,0,0-8.41,11.78q-.69-4.44-1.06-9l-1.23.33Q333,183,333.92,188a132.77,132.77,0,0,0-8,15.14q-1.33-4.26-2.38-8.73l-1.17.49q1.21,5.09,2.8,9.92a133.06,133.06,0,0,0-5.74,16q-1.93-4-3.6-8.27l-1.08.65q1.93,4.85,4.19,9.39a133,133,0,0,0-3.42,16.61q-2.47-3.7-4.73-7.67l-1,.79q2.59,4.52,5.47,8.69a132.82,132.82,0,0,0-1,17q-3-3.33-5.77-6.95l-.86.92q3.2,4.12,6.64,7.86a132.77,132.77,0,0,0,1.38,17.06q-3.42-2.91-6.7-6.12l-.72,1q3.76,3.65,7.7,6.91A133,133,0,0,0,320.46,298l1.1-.35A131.81,131.81,0,0,1,317,278.53q2.73-4.29,5.72-8.21l-.9-.79q-2.65,3.44-5.1,7.18a131.6,131.6,0,0,1-1.36-16.91q3.31-3.82,6.83-7.25l-.78-.9q-3.11,3-6.07,6.32a131.93,131.93,0,0,1,1-16.81q3.81-3.3,7.78-6.18l-.64-1q-3.5,2.53-6.9,5.38A132,132,0,0,1,320,222.9q4.24-2.73,8.58-5l-.5-1.08q-3.83,2-7.59,4.37a131.81,131.81,0,0,1,5.69-15.87Q330.81,203.17,335.44,201.48Z"
      />{' '}
      <path
        fill="#fff"
        d="M324.88,270.34a1.38,1.38,0,0,0,0-1.88,1.25,1.25,0,0,0-1-.39,1.41,1.41,0,0,0-.36-1.1,1.22,1.22,0,0,0-1.8,0,1.42,1.42,0,0,0-.19,1.68l0,0a1.43,1.43,0,0,0,0,1.93,1.22,1.22,0,0,0,1.8,0h0A1.22,1.22,0,0,0,324.88,270.34Z"
      />{' '}
      <path
        fill="#fff"
        d="M306.09,272.29a1.75,1.75,0,0,1-.37-2.14,1.19,1.19,0,0,1,.91-.63,1.73,1.73,0,0,1,.17-1.3,1.14,1.14,0,0,1,1.77-.32,1.71,1.71,0,0,1,.47,1.8l0,0a1.71,1.71,0,0,1,.36,2.09,1.14,1.14,0,0,1-1.76.37h0A1.11,1.11,0,0,1,306.09,272.29Z"
      />{' '}
      <path
        fill="#fff"
        d="M324.31,253.11a1.37,1.37,0,0,0,.24-1.85,1.25,1.25,0,0,0-.93-.52,1.39,1.39,0,0,0-.2-1.13,1.23,1.23,0,0,0-1.78-.29,1.41,1.41,0,0,0-.42,1.62l0,0a1.41,1.41,0,0,0-.25,1.9,1.22,1.22,0,0,0,1.78.24h0A1.23,1.23,0,0,0,324.31,253.11Z"
      />{' '}
      <path
        fill="#fff"
        d="M305.44,252.41a1.73,1.73,0,0,1-.06-2.15,1.19,1.19,0,0,1,1-.49,1.71,1.71,0,0,1,.35-1.25,1.14,1.14,0,0,1,1.8-.07,1.69,1.69,0,0,1,.21,1.83l0,0a1.68,1.68,0,0,1,.06,2.1,1.15,1.15,0,0,1-1.8.12h0A1.11,1.11,0,0,1,305.44,252.41Z"
      />{' '}
      <path
        fill="#fff"
        d="M326.2,235.76a1.36,1.36,0,0,0,.5-1.8,1.26,1.26,0,0,0-.85-.65,1.39,1.39,0,0,0,0-1.15,1.23,1.23,0,0,0-1.72-.53,1.4,1.4,0,0,0-.64,1.55l0,0a1.4,1.4,0,0,0-.51,1.84,1.22,1.22,0,0,0,1.73.49h0A1.23,1.23,0,0,0,326.2,235.76Z"
      />{' '}
      <path
        fill="#fff"
        d="M307.61,232.39a1.72,1.72,0,0,1,.24-2.14,1.19,1.19,0,0,1,1-.35,1.7,1.7,0,0,1,.52-1.19,1.14,1.14,0,0,1,1.79.19,1.68,1.68,0,0,1,0,1.84l0,0a1.68,1.68,0,0,1-.23,2.09,1.15,1.15,0,0,1-1.8-.14h0A1.12,1.12,0,0,1,307.61,232.39Z"
      />{' '}
      <path
        fill="#fff"
        d="M330.49,218.95a1.36,1.36,0,0,0,.75-1.71,1.26,1.26,0,0,0-.75-.77,1.39,1.39,0,0,0,.12-1.15,1.23,1.23,0,0,0-1.63-.77,1.4,1.4,0,0,0-.85,1.44h0a1.41,1.41,0,0,0-.77,1.76,1.22,1.22,0,0,0,1.65.73h0A1.23,1.23,0,0,0,330.49,218.95Z"
      />{' '}
      <path
        fill="#fff"
        d="M312.56,213a1.72,1.72,0,0,1,.54-2.09,1.19,1.19,0,0,1,1.09-.2,1.72,1.72,0,0,1,.68-1.11,1.14,1.14,0,0,1,1.75.44,1.69,1.69,0,0,1-.31,1.82l0,0a1.68,1.68,0,0,1-.53,2,1.15,1.15,0,0,1-1.76-.39h0A1.12,1.12,0,0,1,312.56,213Z"
      />{' '}
      <path
        fill="#fff"
        d="M337,203a1.38,1.38,0,0,0,1-1.6,1.25,1.25,0,0,0-.63-.87,1.41,1.41,0,0,0,.29-1.12,1.22,1.22,0,0,0-1.5-1,1.42,1.42,0,0,0-1.05,1.32h0a1.43,1.43,0,0,0-1,1.64,1.22,1.22,0,0,0,1.53,1h0A1.22,1.22,0,0,0,337,203Z"
      />{' '}
      <path
        fill="#fff"
        d="M320.13,194.61a1.75,1.75,0,0,1,.83-2,1.19,1.19,0,0,1,1.11,0,1.73,1.73,0,0,1,.84-1,1.14,1.14,0,0,1,1.67.67A1.71,1.71,0,0,1,324,194v0a1.7,1.7,0,0,1-.81,2,1.14,1.14,0,0,1-1.69-.63h0A1.11,1.11,0,0,1,320.13,194.61Z"
      />{' '}
      <path
        fill="#fff"
        d="M346,187.87a1.41,1.41,0,0,0,1.22-1.46,1.25,1.25,0,0,0-.5-1,1.44,1.44,0,0,0,.45-1.08,1.21,1.21,0,0,0-1.34-1.2,1.45,1.45,0,0,0-1.25,1.17h0a1.46,1.46,0,0,0-1.25,1.5,1.21,1.21,0,0,0,1.38,1.16h0A1.21,1.21,0,0,0,346,187.87Z"
      />{' '}
      <path
        fill="#fff"
        d="M330.41,177.15a1.79,1.79,0,0,1,1.13-1.89,1.18,1.18,0,0,1,1.1.11,1.78,1.78,0,0,1,1-.89,1.13,1.13,0,0,1,1.56.9,1.75,1.75,0,0,1-.83,1.69v0a1.74,1.74,0,0,1-1.1,1.84,1.13,1.13,0,0,1-1.59-.86h0A1.1,1.1,0,0,1,330.41,177.15Z"
      />{' '}
      <path
        fill="#fff"
        d="M346.46,175.22a1.38,1.38,0,0,0,0-1.93,1.19,1.19,0,0,0-1.06-.24A1.23,1.23,0,0,0,345,172a1.38,1.38,0,0,0-1.93.31,1.45,1.45,0,0,0-.16,1.74l0,0a1.28,1.28,0,1,0,2,1.61h0A1.43,1.43,0,0,0,346.46,175.22Z"
      />{' '}
      <path fill="#adc43b" d="M341,201l-24.33,35.06S350.54,232.31,341,201Z" />{' '}
      <path fill="#92b04c" d="M341,201,316.69,236S308.37,203,341,201Z" />{' '}
      <path
        fill="#75d0d8"
        d="M337,213.48a4.36,4.36,0,1,0-4.27-7.6l-.06,0-11.95,8.43,10.41-10.17.09-.1a4.36,4.36,0,1,0-6.71-5.57l0,0L314.15,214,321,196.77l0-.12a4.36,4.36,0,0,0-8.39-2.36l0,.06-3.13,18.13-.76-18.31V194a4.36,4.36,0,0,0-6.45-3.18,4.39,4.39,0,0,0-2.18,4.43v.06l3.62,14.38-5.86-13.55-.06-.12a4.36,4.36,0,0,0-7.6,4.27l0,.06,10.1,14.28-12.63-12-.1-.08a4.36,4.36,0,1,0-5.33,6.9l.05,0,13.82,8.57-15.06-6-.12,0a4.36,4.36,0,0,0-2.36,8.4l.06,0,15.73,2.7-15.9.07h-.13a4.36,4.36,0,0,0,.93,8.67h.06l17.35-3.69-16.22,7-.12.06a4.36,4.36,0,0,0,4.27,7.6l.06,0,11.94-8.45-10.41,10.19-.09.09a4.36,4.36,0,0,0,.57,6.14,4.36,4.36,0,0,0,6.14-.57l0,0,10.36-15.46L296.42,252l0,.12a4.36,4.36,0,0,0,8.4,2.36l0-.06,3.14-18.33.74,18.52v.13a4.36,4.36,0,1,0,8.63-1.25v-.06L313.67,239l5.87,13.55.06.11a4.36,4.36,0,1,0,7.6-4.27l0-.06-10.08-14.29,12.61,12,.1.08a4.36,4.36,0,0,0,5.33-6.9l-.05,0-13.83-8.56,15.07,6,.12,0a4.36,4.36,0,0,0,2.36-8.4l-.06,0L323,225.57l15.9,0H339a4.36,4.36,0,1,0-.93-8.67H338l-17.34,3.71,16.21-7Z"
      />
      <circle
        fill="#6da6ab"
        cx="308.83"
        cy="224.4"
        r="9.77"
        transform="translate(-70.33 179.99) rotate(-29.32)"
      />{' '}
      <path
        fill="#da200b"
        d="M363.77,261.49c-42.54,12.79-29.17,36.58,3.88,6.91,12.79,42.54,36.58,29.17,6.91-3.88,42.54-12.79,29.18-36.58-3.88-6.91C357.89,215.07,334.1,228.44,363.77,261.49Z"
      />{' '}
      <path
        fill="#da200b"
        d="M364.28,265.75c-21,39.12,5.24,46.5,7.63,2.14,39.12,21,46.5-5.24,2.14-7.63,21-39.12-5.24-46.5-7.63-2.14C327.3,237.08,319.93,263.36,364.28,265.75Z"
      />
      <circle
        fill="#fff"
        cx="369.17"
        cy="263.01"
        r="5.68"
        transform="translate(-81.5 214.49) rotate(-29.32)"
      />{' '}
      <path
        fill="#adc43b"
        d="M325.81,269.5l17.95,38.72S357.62,277.1,325.81,269.5Z"
      />{' '}
      <path
        fill="#92b04c"
        d="M325.79,269.47l17.95,38.72S311,298.65,325.79,269.47Z"
      />{' '}
      <path
        fill="#adc43b"
        d="M325.47,262.56,294,291.36S327.84,295.19,325.47,262.56Z"
      />{' '}
      <path
        fill="#92b04c"
        d="M325.5,262.53,294,291.33S293.22,257.27,325.5,262.53Z"
      />{' '}
      <path
        fill="#adc43b"
        d="M367.78,273.25l-25.37,45.13S382.88,309.94,367.78,273.25Z"
      />{' '}
      <path
        fill="#92b04c"
        d="M367.81,273.21l-25.37,45.13S328.61,279.38,367.81,273.21Z"
      />{' '}
      <path
        fill="#adc43b"
        d="M406.39,233.29l-9,41.72S427.21,258.53,406.39,233.29Z"
      />{' '}
      <path
        fill="#92b04c"
        d="M406.4,233.25l-9,41.72S377,247.65,406.4,233.25Z"
      />{' '}
      <path
        fill="#adc43b"
        d="M298.27,211.2l7.54,42S327.12,226.62,298.27,211.2Z"
      />{' '}
      <path
        fill="#92b04c"
        d="M298.26,211.16l7.54,42S276.57,235.64,298.26,211.16Z"
      />{' '}
      <path
        fill="#adc43b"
        d="M356.89,215.7,336.18,253S369.5,245.88,356.89,215.7Z"
      />{' '}
      <path
        fill="#92b04c"
        d="M356.91,215.66,336.2,253S324.63,220.93,356.91,215.66Z"
      />{' '}
      <path
        fill="#adc43b"
        d="M238.21,239.24l18,24.43S261.47,240,238.21,239.24Z"
      />{' '}
      <path
        fill="#92b04c"
        d="M238.2,239.22l18,24.43S232.07,261.67,238.2,239.22Z"
      />{' '}
      <path
        fill="#fced29"
        d="M294.83,243.86a8.9,8.9,0,1,0-13.19-11.54,8.9,8.9,0,1,0-17.27,3.17,8.9,8.9,0,1,0-11.87,13,8.91,8.91,0,1,0,4.7,16.66A8.91,8.91,0,1,0,269,277.82a8.9,8.9,0,1,0,17.26-3.38,8.9,8.9,0,1,0,11.29-13.33,8.9,8.9,0,1,0-2.7-17.26Z"
      />
      <circle
        fill="#fff"
        cx="275.75"
        cy="255.63"
        r="8.14"
        transform="translate(-89.86 167.79) rotate(-29.32)"
      />
      <polygon
        fill="#ffeea5"
        points="232.48 250.26 358.75 296.71 421.02 239.21 364.27 379.97 351.7 396.57 333.24 390.67 290.88 339.25 270.72 307.36 232.48 250.26"
      />
      <polygon
        fill="#ffeea5"
        points="351.7 396.57 364.27 379.97 421.02 239.21 358.75 296.71 350.67 396.24 351.7 396.57"
      />{' '}
      <path
        fill="#fca2b4"
        d="M355.94,246.35a4.36,4.36,0,1,0-4.27-7.6l-.06,0-11.95,8.43L350.07,237l.09-.1a4.36,4.36,0,1,0-6.71-5.57l0,.05-10.34,15.47,6.82-17.25,0-.12a4.36,4.36,0,0,0-8.4-2.36l0,.06-3.13,18.13L327.63,227v-.13a4.36,4.36,0,0,0-6.45-3.18,4.39,4.39,0,0,0-2.18,4.43v.06l3.62,14.38-5.86-13.55-.06-.12a4.36,4.36,0,0,0-7.6,4.27l0,.06,10.1,14.28-12.63-12-.1-.08a4.36,4.36,0,0,0-5.33,6.9l.05,0L315.05,251,300,245l-.12,0a4.36,4.36,0,0,0-2.36,8.4l.06,0,15.73,2.7-15.9.07h-.13a4.36,4.36,0,0,0,.93,8.67h.06l17.35-3.69-16.22,7-.12.06a4.36,4.36,0,0,0,4.27,7.6l.06,0,11.94-8.45-10.41,10.19-.09.09a4.36,4.36,0,0,0,.57,6.14,4.36,4.36,0,0,0,6.14-.57l0,0,10.36-15.46-6.83,17.24,0,.12a4.36,4.36,0,0,0,8.4,2.36l0-.06L326.85,269l.74,18.52v.13a4.36,4.36,0,1,0,8.63-1.25v-.06l-3.64-14.37,5.87,13.55.06.11a4.36,4.36,0,0,0,7.6-4.27l0-.06L336,267l12.61,12,.1.08a4.36,4.36,0,0,0,5.33-6.9l-.05,0-13.83-8.55,15.07,6,.12,0a4.36,4.36,0,1,0,2.36-8.39l-.06,0-15.73-2.72,15.9,0h.13a4.36,4.36,0,1,0-.93-8.67h-.06l-17.34,3.71,16.21-7Z"
      />
      <circle
        fill="#685656"
        cx="327.74"
        cy="257.28"
        r="9.77"
        transform="translate(-84 193.47) rotate(-29.32)"
      />{' '}
      <path
        fill="#cc0515"
        d="M384.19,376.59c-5.25-2.53-10.33.33-12,1.26A71.23,71.23,0,0,0,366,382a84.62,84.62,0,0,1-7.63,5h0a25.68,25.68,0,0,1-8.42,3.18c-5.86.95-12.22-1-17.83-2.74-6.41-2-11.95-3.69-16.84-.94-7.2,4-5.68,13.49-5.1,17,.82,5.06,2.43,11.19,7.73,13.75s10.3-.31,12-1.25h0a71.11,71.11,0,0,0,6.17-4.1,84.67,84.67,0,0,1,7.64-5,25.69,25.69,0,0,1,8.42-3.18c5.86-.95,12.22,1,17.82,2.75,6.41,2,11.95,3.69,16.84.94h0c7.2-4,5.68-13.49,5.1-17C391.1,385.28,389.49,379.14,384.19,376.59Zm-40,22.92c-10.37,4.56-16.89,15.71-19,2.37-2.12-13.11,7.21-5.11,18.24-3.81Zm29-5.39c2.17,13.45-7.71,4.67-19.11,3.72l-.6-1.08C364.22,392.41,371,380.5,373.18,394.12Z"
      />{' '}
      <path
        fill="#464477"
        d="M369.45,436.89a9.18,9.18,0,0,1-1.64-18.21c1.34-1.61,3.21-6.47,2-10.46-.35-1.21-1.3-4.41-8.45-5.06-28.32-2.57-67.35-26.8-69-27.82a9.18,9.18,0,0,1,9.72-15.56c.37.23,37,22.93,60.94,25.11,19.64,1.79,26,15.92,25.47,27.48C388,424.16,380.55,436.89,369.45,436.89Zm0-18.35h0Z"
      />{' '}
      <path
        fill="#9e9e9e"
        d="M337.23,445.25c-6.61,9.91-39.68-3.32-46.29-6.63a9.64,9.64,0,0,1-1.1-.65c-19.47,7.22-51.32,11-53.75,11-3.3,0-40.78,0-60.91-13.68-5.63-3.83-14.52-14-21.1-22.12-3.75,9.69-15.56,30.85-31.81,25.43-19.84-6.61-3.3-23.14-3.3-23.14s16.54-13.23,3.3-39.68-18.19-87.64,1.65-140.56,89.29-86,89.29-86l1,6.85a.16.16,0,0,0,0,.06l3.89,27.22.08.59s28.1,11.66,28.1,18.29c19.84-9.93,31.42-5.08,31.42-5.08S254.57,220.35,254.57,227s3.3,49.61-16.54,59.52c0,3.14,3,9.26,6.12,14.82a24,24,0,0,0,7.12-4.89c9.91-9.93,9.91-29.77,23.14-29.77s23.14,3.32,16.54,13.23-13.23,6.61-13.23,19.84v19.84l.49,2.37c9.89-6.36,25.77,1.55,31.93,11.91a72.71,72.71,0,0,1,9.81,40.29c-.73,16.17-1.41,40.33.77,44.66C324,425.39,343.86,435.32,337.23,445.25Z"
      />{' '}
      <path
        fill="#070707"
        d="M237,264l6.27-4.82a.38.38,0,0,0-.19-.67l-11.51-1.09a.38.38,0,0,0-.32.62l5.25,5.91A.38.38,0,0,0,237,264Z"
      />{' '}
      <path
        fill="#464477"
        d="M122.27,375.92l2.23,4.87.89,2.53a22.26,22.26,0,0,1,.82,2.55,38.17,38.17,0,0,1,1.07,5.26,26.07,26.07,0,0,1,.29,2.68l.05,2.7A30,30,0,0,1,125.36,407a24.93,24.93,0,0,1-6.14,8.89l0,0a29.57,29.57,0,0,0-4.21,5.72,14.74,14.74,0,0,0-2.07,6.64,8.11,8.11,0,0,0,2.51,6.16,18.93,18.93,0,0,0,12.89,4.67,16.81,16.81,0,0,0,6.71-1.9,32.35,32.35,0,0,0,10.5-9.46,63.9,63.9,0,0,0,7.21-12.39c.49-1.09.94-2.2,1.35-3.32a11.64,11.64,0,0,0,.85-3.38c.13-2.44.38-4.82.6-7.23.9-9.61,2-19.19,3.06-28.78l-2.44,28.84c-.18,2.4-.4,4.82-.5,7.19a5.91,5.91,0,0,1-.08,1c-.06.31-.13.62-.21.91-.16.6-.35,1.18-.55,1.75-.4,1.15-.84,2.28-1.33,3.4a63.41,63.41,0,0,1-7.24,12.61A33.25,33.25,0,0,1,135.53,438a16.72,16.72,0,0,1-14.37.81,17.49,17.49,0,0,1-6.33-3.79,9,9,0,0,1-2.75-6.85,15.59,15.59,0,0,1,2.21-7,28.89,28.89,0,0,1,4.39-5.87h0l0,0a24.29,24.29,0,0,0,6-8.52,29.42,29.42,0,0,0,2.35-10.27v-2.66a25.65,25.65,0,0,0-.22-2.65,37.83,37.83,0,0,0-.93-5.25,22.09,22.09,0,0,0-.75-2.56l-.82-2.55Z"
      />{' '}
      <path
        fill="#464477"
        d="M277.71,319.69l-.24-17.19a33.74,33.74,0,0,1,.59-8.63,10.75,10.75,0,0,1,2-3.92,18.2,18.2,0,0,1,3.27-2.89,28.73,28.73,0,0,0,6.16-5.78,20.74,20.74,0,0,0,2.24-3.54,7.39,7.39,0,0,0,.83-3.91,4.88,4.88,0,0,0-2-3.27,12,12,0,0,0-3.69-1.81,35.29,35.29,0,0,0-8.34-1.35c-1.42-.09-2.84-.13-4.26-.13a10.2,10.2,0,0,0-4,.79,13.49,13.49,0,0,0-5.93,5.63c-3,4.83-4.7,10.36-7.44,15.44a32.07,32.07,0,0,1-4.94,7.11,25.12,25.12,0,0,1-7,5.08,25.43,25.43,0,0,1-8.3,2.29,26.22,26.22,0,0,1-8.56-.46,26.8,26.8,0,0,0,16.65-2.27,24.7,24.7,0,0,0,6.75-5.07,31.39,31.39,0,0,0,4.72-7c1.29-2.54,2.39-5.17,3.53-7.8a62.1,62.1,0,0,1,3.82-7.76,14.4,14.4,0,0,1,6.32-6,11.17,11.17,0,0,1,4.37-.89c1.45,0,2.89,0,4.33.11a36.21,36.21,0,0,1,8.57,1.44,12.86,12.86,0,0,1,4,2,5.81,5.81,0,0,1,2.32,3.89,8.26,8.26,0,0,1-.93,4.39,21.58,21.58,0,0,1-2.36,3.69,28.05,28.05,0,0,1-6.38,5.87,17.54,17.54,0,0,0-3.2,2.73,10.08,10.08,0,0,0-1.93,3.64,33.66,33.66,0,0,0-.71,8.46Z"
      />{' '}
      <path
        fill="#464477"
        d="M241.33,326.31a38,38,0,0,0,9.33.84,64.73,64.73,0,0,0,9.34-.92,84.37,84.37,0,0,0,17.92-5.44c.71-.31,1.46-.57,2.22-.84a13.7,13.7,0,0,1,2.3-.61,19.63,19.63,0,0,1,4.74-.32,25.47,25.47,0,0,1,9.16,2.34,32.32,32.32,0,0,1,14,12.38,70.73,70.73,0,0,1,7.38,17.34,75,75,0,0,1,2.64,18.67c.07,3.16-.14,6.3-.24,9.42s-.21,6.26-.29,9.39c-.15,6.26-.23,12.53,0,18.78a68.6,68.6,0,0,0,.73,9.29l.23,1.11c.13.33.23.68.37,1a10.83,10.83,0,0,0,1.27,1.84,38.33,38.33,0,0,0,3.26,3.3c2.3,2.11,4.71,4.13,7,6.35a24.3,24.3,0,0,1,5.67,7.64,8.45,8.45,0,0,1,.49,4.85,8,8,0,0,1-2.48,4.2,9.92,9.92,0,0,1-4.4,2,20.86,20.86,0,0,1-4.76.42c-6.35-.14-12.51-1.71-18.52-3.5a135.13,135.13,0,0,1-17.6-6.72,20,20,0,0,1-3.87-2.77c-1.2-1-2.3-2.15-3.41-3.27-2.15-2.3-4.22-4.66-6.14-7.15a102.78,102.78,0,0,1-10.17-15.83,62.07,62.07,0,0,1-6.28-17.66,62,62,0,0,0,6.5,17.55A102.41,102.41,0,0,0,278,425.64c1.93,2.46,4,4.81,6.14,7.08,1.11,1.1,2.21,2.21,3.39,3.22a19.5,19.5,0,0,0,3.76,2.67,134.58,134.58,0,0,0,17.54,6.61c6,1.76,12.12,3.28,18.33,3.39a20.11,20.11,0,0,0,4.59-.42,9.16,9.16,0,0,0,4.05-1.85,7.2,7.2,0,0,0,2.2-3.77,7.63,7.63,0,0,0-.47-4.37,23.54,23.54,0,0,0-5.51-7.32c-2.22-2.18-4.63-4.18-7-6.3a39.13,39.13,0,0,1-3.36-3.37,11.71,11.71,0,0,1-1.4-2c-.17-.38-.3-.79-.45-1.19l-.26-1.18a68.23,68.23,0,0,1-.79-9.44c-.15-6.29,0-12.56.13-18.83q.13-4.7.33-9.4c.11-3.14.34-6.26.28-9.38A74.17,74.17,0,0,0,317,351.28a70,70,0,0,0-7.23-17.2,31.71,31.71,0,0,0-13.72-12.19,24.91,24.91,0,0,0-8.95-2.33,19.11,19.11,0,0,0-4.61.29,22.88,22.88,0,0,0-4.43,1.39A84.74,84.74,0,0,1,260,326.47a64.91,64.91,0,0,1-9.37.8A38.07,38.07,0,0,1,241.33,326.31Z"
      />{' '}
      <path
        fill="#464477"
        d="M244.64,412.3l3.45.72,3.43.75,6.86,1.48c2.29.5,4.58.94,6.87,1.42,1.15.22,2.3.45,3.45.64s2.31.39,3.47.62a56.79,56.79,0,0,1-7-.5c-2.34-.3-4.65-.72-7-1.2s-4.59-1.05-6.84-1.72S246.83,413.16,244.64,412.3Z"
      />{' '}
      <path
        fill="#070707"
        d="M195,223.78a23.07,23.07,0,0,0,1.54,4,22.48,22.48,0,0,0,2.17,3.69,20.44,20.44,0,0,0,6.17,5.74,22,22,0,0,0,8,2.81,28.49,28.49,0,0,0,4.28.37c.72,0,1.44,0,2.16,0s1.44-.09,2.17-.11c-.71.14-1.43.27-2.15.38s-1.45.17-2.18.23a28.38,28.38,0,0,1-4.39-.1,20.16,20.16,0,0,1-8.38-2.71,19,19,0,0,1-6.29-6.17A20.59,20.59,0,0,1,195,223.78Z"
      />{' '}
      <path
        fill="#070707"
        d="M241.33,243.63a16.52,16.52,0,0,0,2.26,3.41,15,15,0,0,0,3,2.69,8.79,8.79,0,0,0,.84.54,7,7,0,0,0,.87.49l.9.46.94.37c.62.28,1.29.42,1.93.65s1.34.31,2,.52c-.69,0-1.4,0-2.1,0a13.22,13.22,0,0,1-2.08-.39,12.25,12.25,0,0,1-3.88-1.78,11.7,11.7,0,0,1-3-3A9.89,9.89,0,0,1,241.33,243.63Z"
      />{' '}
      <path
        fill="#070707"
        d="M220.72,263.47a23.75,23.75,0,0,0,3.78,3.63,8.26,8.26,0,0,0,4.64,1.81,6.74,6.74,0,0,0,4.51-1.73,14,14,0,0,0,3.17-3.93h0a.49.49,0,0,1,.9.09,20.77,20.77,0,0,0,1.44,3.51,8,8,0,0,0,2.3,2.85,2.66,2.66,0,0,0,1.65.47,3.62,3.62,0,0,0,1.68-.64,11.24,11.24,0,0,0,2.67-2.75,10.2,10.2,0,0,1-2.43,3.09,3.73,3.73,0,0,1-1.87.86,3.29,3.29,0,0,1-2.06-.45,8.06,8.06,0,0,1-2.72-3,18.71,18.71,0,0,1-1.6-3.66l.9.08a13.71,13.71,0,0,1-3.42,4.25,7.42,7.42,0,0,1-5.17,1.82,8.92,8.92,0,0,1-4.93-2.24A19.37,19.37,0,0,1,220.72,263.47Z"
      />{' '}
      <path
        fill="#070707"
        d="M198.38,258a1.48,1.48,0,0,0-.62-1,6.59,6.59,0,0,0-1-.67,17.49,17.49,0,0,0-2.13-1,42,42,0,0,0-4.47-1.61,77.49,77.49,0,0,0-9.24-2.19,57.37,57.37,0,0,0-9.42-.91,28.07,28.07,0,0,0-4.71.34,15.7,15.7,0,0,0-4.54,1.35,14.2,14.2,0,0,1,4.44-1.83,28.09,28.09,0,0,1,4.79-.63,44.89,44.89,0,0,1,9.61.7,56,56,0,0,1,9.31,2.45,40.89,40.89,0,0,1,4.43,1.89,17.84,17.84,0,0,1,2.06,1.26,6.57,6.57,0,0,1,.92.79A1.55,1.55,0,0,1,198.38,258Z"
      />{' '}
      <path
        fill="#070707"
        d="M198.09,265.92a1.48,1.48,0,0,0-.72-.89,6.59,6.59,0,0,0-1-.56,17.49,17.49,0,0,0-2.23-.79,42,42,0,0,0-4.62-1.1,77.49,77.49,0,0,0-9.42-1.15,57.37,57.37,0,0,0-9.47.14,28.06,28.06,0,0,0-4.65.86,15.69,15.69,0,0,0-4.36,1.85,14.2,14.2,0,0,1,4.21-2.31,28.09,28.09,0,0,1,4.69-1.16,44.89,44.89,0,0,1,9.63-.38,56,56,0,0,1,9.52,1.4,40.89,40.89,0,0,1,4.62,1.39,17.84,17.84,0,0,1,2.19,1,6.57,6.57,0,0,1,1,.68A1.55,1.55,0,0,1,198.09,265.92Z"
      />{' '}
      <path
        className="cls-20"
        d="M218.11,183.39C207.91,180.18,179,182,179,182c8.16-12.23,35.2-25.9,35.2-25.9a.16.16,0,0,0,0,.06Z"
      />{' '}
      <path
        fill="#561819"
        d="M154.4,401.42s17.73-57.45-36.74-60.33S82,401.42,82,401.42,126.08,428.14,154.4,401.42Z"
      />{' '}
      <path
        fill="#fab217"
        d="M82,449h69.15s15.2-66.87,8.36-74.47-12.92,18.24-12.92,18.24,2.28-22-6.08-23.56-12.92,23.56-12.92,23.56-5.39-29.62-15.2-23.63c-7.67,4.69-9,18.31-9,18.31s-.5-17-6.39-18.38-10,20.5-10,20.5S78.86,362.22,74.39,369C71.85,372.85,82,449,82,449Z"
      />
      <polygon
        fill="#52668a"
        points="115.38 354.06 115.38 321.06 120.28 312.36 125.83 322.69 125.83 354.06 115.38 354.06"
      />
      <polygon
        fill="#f3e6d5"
        points="76.75 403.47 159.26 406.37 158.53 411.21 77.82 408.9 76.75 403.47"
      />{' '}
      <path
        fill="#f3e6d5"
        d="M155.87,414.85s-1.69,12.06-4.1,12.76-8.44-12.76-8.44-12.76-5.06,12.34-7.48,12.78-6.51-14.4-6.51-14.4-2.41,10.9-5.79,13-5.55-13-5.55-13-3.13,14.91-7.24,14.41-5.89-14.41-5.89-14.41-4.72,12.29-8.82,13.22-4.25-11.13-4.25-11.13-2.37,9.51-4.37,10.44-8.26-12.52-8.26-12.52,3.86,15.54,6.75,16.23,5.88-5.56,5.88-5.56,2.81,6.37,5.46,5.62,7.61-9.1,7.61-9.1,1.55,8.81,4.69,9.16,8-8.17,8-8.17,2.32,8.08,5.5,8.18,6.32-9.17,6.32-9.17.94,10.9,5.05,10.9,8.69-9.79,8.69-9.79,3.14,6.28,7.24,7.93S156.83,421.34,155.87,414.85Z"
      />{' '}
      <path
        fill="#882c19"
        d="M142.84,351s4.69,9.25,1.67,11.27S129.84,351,129.84,351s.75,10.88-3,13.14S114.44,351,114.44,351s-2.57,11-6.8,12.69-5.74-13.29-5.74-13.29-9.88,9-13.44,9.06,3.43-19.85,23.42-19.89C139.72,339.47,142.84,351,142.84,351Z"
      />{' '}
      <path
        fill="#ee4623"
        d="M120.31,289.2s-11.51,24.07.4,24.55S120.31,289.2,120.31,289.2Z"
      />{' '}
      <path
        fill="#fab217"
        d="M121,298.58s-6,11.3-.36,13.81S121,298.58,121,298.58Z"
      />
      <polygon
        fill="#52668a"
        points="152.06 190.62 239.23 192.95 192.09 73.13 152.06 190.62"
      />
      <polygon
        fill="#fab217"
        points="161.12 164.06 228.45 165.53 235.66 183.88 155.07 181.81 161.12 164.06"
      />{' '}
      <path
        fill="#f5efdb"
        d="M228.76,169.56s.91,9.14-2.79,10.4-8.88-9.58-8.88-9.58-1.65,11.57-4.16,11.36-8.73-12.06-8.73-12.06-4,12.39-8.29,10.87-8-13-8-13-.91,14-5,13-7.9-11.67-7.9-11.67-2.33,10.48-6.27,9.88c-2.79-.42-5.21-12.34-5.21-12.34s4.15,9.65,5.92,8.76,5.48-9.3,5.48-9.3,6.35,12.71,8.21,11.12,4.08-11.21,4.08-11.21,6.94,11.78,9.12,11.36,7.1-10.86,7.1-10.86,7.3,10.83,8.57,10.2,4.61-9.87,4.61-9.87,5.89,10.78,8.08,10.32S228.76,169.56,228.76,169.56Z"
      />{' '}
      <path
        fill="#fab217"
        d="M192.05,75.46S179.34,57,162.58,86.6c0,0-2.29-32.06,26.59-19.49,0,0-13.49-19.27-32.16,1.37,0,0,14.07-36.53,34.16-4,0,0,8.68-18.77,20.72-9.43,0,0-18.13,4.2-18.56,13.14,0,0,23.93-18.63,17.33,15.37C210.68,83.58,199,59.46,192.05,75.46Z"
      />
      <polygon
        fill="#fab217"
        points="168.2 143.26 220.17 144.5 213.27 126.95 174.13 125.84 168.2 143.26"
      />
      <polygon
        fill="#fab217"
        points="178.97 111.65 207.13 111.45 200.67 94.93 184.35 95.85 178.97 111.65"
      />{' '}
      <path
        fill="#f5efdb"
        d="M174.88,129.86s1.62,10.12,4.24,10.72,6.81-10.67,6.81-10.67,1.47,11.25,4,11.21,6.09-11.41,6.09-11.41,1.73,10.9,4,11.32,4.53-10.2,4.53-10.2,2.58,10.75,5.66,10.78S214.46,130,214.46,130s-3.11,7.92-4.61,7.65l-1.51-.27-3.5-8.66s-2.71,8.22-4.29,8.4-4.21-9.52-4.21-9.52-4.14,10.49-6.68,9.5-3.83-9.38-3.83-9.38-5.63,9.54-6.77,9.39S174.88,129.86,174.88,129.86Z"
      />{' '}
      <path
        fill="#dbeccc"
        d="M201.47,99s2.72,10.69-.31,11.06c-1.14.14-5.06-8-5.06-8s-1.17,8.44-3.83,8.09-4-8.63-4-8.63-1.62,8.37-3.61,7.85-1.36-9.15-1.36-9.15.42,7.77,1.67,7,3-8.22,3-8.22,2.4,9.06,3.94,8.11,3.75-8.93,3.75-8.93S200,108,201,106.75,201.47,99,201.47,99Z"
      />
    </svg>
  )
}

export default IconBirthdayCat
