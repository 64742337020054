import React from 'react'

const IconXmasCat = (props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width}
      height={props.height}
      viewBox="0 0 500 500"
      aria-labelledby="title"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="63.06"
          y1="363.06"
          x2="92.46"
          y2="363.06"
          gradientTransform="translate(65.35 -209.49) rotate(-5.89)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#665391" />
          <stop offset="1" stopColor="#4f4f4f" />
        </linearGradient>
      </defs>
      <title id="title">Cat at Christmas</title>
      <path
        fill="#464477"
        d="M320.79,450.6a8.5,8.5,0,0,1,0-17h.39a17.61,17.61,0,0,0-1.16-5.8c-4.92-14.77,10.08-21.39,21-26.22,8.7-3.84,18.46-8.14,24.48-15.44-12.47-5.35-57.65-5.35-73.28-5.35-5.32,0-9.8,0-12.94-.08-3.94-.1-13.17-.32-13.17-9a8.51,8.51,0,0,1,11.23-8.05c2.46.16,9,.16,14.88.16,54.45,0,83.36,2.34,90.21,15.15,2.4,4.48,2,9.75-1.17,14.46-8.52,12.77-22.3,18.85-33.38,23.74-3.91,1.72-9.63,4.25-11.56,5.94,2.79,8.81,2.35,15.81-1.3,20.87C331.95,448.23,326.89,450.6,320.79,450.6Zm-37.85-80.79a8.52,8.52,0,0,1,.2,1.85A8.49,8.49,0,0,0,282.94,369.82Z"
      />
      <path
        fill="#9e9e9e"
        d="M278.72,182.23C249.58,111.8,235,119.08,235,119.08H206.64s-12.14-7.29-41.29,63.15-72.86,194.3-34,213.73a237.6,237.6,0,0,0,42.08,16.15c15.36,5.74,31.94,7.91,48.61,7.54,16.67.37,33.24-1.79,48.61-7.54A237.61,237.61,0,0,0,312.72,396C351.58,376.53,307.87,252.66,278.72,182.23Z"
      />
      <ellipse fill="#f7f789" cx="201.18" cy="176.77" rx="9.47" ry="10.93" />
      <ellipse fill="#070707" cx="201.18" cy="176.77" rx="7.89" ry="9.11" />
      <ellipse fill="#f7f789" cx="235.18" cy="176.77" rx="9.47" ry="10.93" />
      <ellipse fill="#070707" cx="235.18" cy="176.77" rx="7.89" ry="9.11" />
      <path
        fill="#070707"
        d="M221.47,199.8l5.12-4.77a.38.38,0,0,0-.26-.65H216.1a.38.38,0,0,0-.26.65L221,199.8A.38.38,0,0,0,221.47,199.8Z"
      />
      <path
        fill="#070707"
        d="M235.79,195.45a25.67,25.67,0,0,1,6.57-.57,28.5,28.5,0,0,1,6.56,1,23.25,23.25,0,0,1,6.11,2.61,20.6,20.6,0,0,1,5,4.25q-.67-.49-1.31-1l-.64-.5c-.22-.15-.46-.29-.69-.44l-1.35-.89-1.43-.76a30,30,0,0,0-6-2.32,40.08,40.08,0,0,0-6.35-1.19c-1.08-.11-2.16-.19-3.24-.21S236.88,195.41,235.79,195.45Z"
      />
      <path
        fill="#070707"
        d="M235.79,200.85a26,26,0,0,1,11,2.31,21.33,21.33,0,0,1,4.83,3,15.91,15.91,0,0,1,3.59,4.37,25.18,25.18,0,0,0-4.07-3.76,28.62,28.62,0,0,0-4.76-2.74,41.25,41.25,0,0,0-5.18-1.91A53.85,53.85,0,0,0,235.79,200.85Z"
      />
      <path
        fill="#070707"
        d="M206.64,195.45c-1.1,0-2.18-.07-3.27,0s-2.17.1-3.24.21a40.08,40.08,0,0,0-6.35,1.19,30,30,0,0,0-6,2.32l-1.43.76-1.35.89c-.23.15-.47.28-.69.44l-.64.5q-.64.51-1.31,1a20.6,20.6,0,0,1,5-4.25,23.24,23.24,0,0,1,6.11-2.61,28.5,28.5,0,0,1,6.56-1A25.67,25.67,0,0,1,206.64,195.45Z"
      />
      <path
        fill="#070707"
        d="M206.64,200.85a53.85,53.85,0,0,0-5.43,1.31,41.25,41.25,0,0,0-5.18,1.91,28.62,28.62,0,0,0-4.76,2.74,25.18,25.18,0,0,0-4.07,3.76,15.91,15.91,0,0,1,3.59-4.37,21.33,21.33,0,0,1,4.83-3A26,26,0,0,1,206.64,200.85Z"
      />
      <path
        fill="#f49595"
        d="M194.87,144.77s-1.8,3.57-4.56,8c-4.39,7.1-11.2,16.39-16.86,16.75-9.21.58-13.37-32.37-9.12-34.3S194.87,144.77,194.87,144.77Z"
      />
      <path
        fill="url(#linear-gradient)"
        d="M194.87,144.77s-1.8,3.57-4.56,8c-5.68-6.19-16.71-15.11-26-17.54C168.59,133.3,194.87,144.77,194.87,144.77Z"
      />
      <path
        fill="#9e9e9e"
        d="M301.85,364.21c-8.47,15.84-31.7,56.65-48,60.41-20.23,4.68-6.49-12.71,2.06-21.79,6.27-6.66,13.79-32.69,17.35-46.11"
      />
      <path
        fill="#9e9e9e"
        d="M138.39,364.21c8.47,15.84,31.7,56.65,48,60.41,20.23,4.68,6.49-12.71-2.06-21.79-6.27-6.66-13.79-32.69-17.35-46.11"
      />
      <path
        fill="#464477"
        d="M301.85,364.21q-4.72,9.07-9.89,17.92c-3.48,5.87-7.13,11.65-11,17.26a130.5,130.5,0,0,1-12.83,16,50.43,50.43,0,0,1-7.79,6.75,23.85,23.85,0,0,1-4.61,2.44,27.69,27.69,0,0,1-5.09,1.16,20.86,20.86,0,0,1-2.62.16c-.44,0-.89-.06-1.34-.09a7.11,7.11,0,0,1-1.34-.34,3.45,3.45,0,0,1-2.13-1.91,4.78,4.78,0,0,1-.14-2.79,17.11,17.11,0,0,1,2-4.84,46.11,46.11,0,0,1,2.86-4.3,97.59,97.59,0,0,1,6.59-7.88,22.34,22.34,0,0,0,3.13-3.93,44.35,44.35,0,0,0,2.42-4.45,164.06,164.06,0,0,0,7.3-19.05c2.15-6.47,4.09-13,5.93-19.57-1.68,6.61-3.47,13.19-5.46,19.72a163.22,163.22,0,0,1-7.07,19.23,45.12,45.12,0,0,1-2.4,4.57,23,23,0,0,1-3.2,4.07,96.74,96.74,0,0,0-6.49,7.84,45.23,45.23,0,0,0-2.78,4.23,16.26,16.26,0,0,0-1.9,4.56,3.85,3.85,0,0,0,.09,2.24,2.47,2.47,0,0,0,1.58,1.37,6.16,6.16,0,0,0,1.13.28c.4,0,.8.08,1.21.08a20.63,20.63,0,0,0,2.48-.16,26.86,26.86,0,0,0,4.88-1.08,22.91,22.91,0,0,0,4.42-2.32,49.54,49.54,0,0,0,7.67-6.59,139.51,139.51,0,0,0,12.86-15.8c4-5.54,7.67-11.26,11.23-17.07S298.56,370.18,301.85,364.21Z"
      />
      <path
        fill="#464477"
        d="M138.39,364.21q4.94,9,10.3,17.67c3.56,5.81,7.27,11.53,11.23,17.07a139.51,139.51,0,0,0,12.86,15.8,49.54,49.54,0,0,0,7.67,6.59,22.91,22.91,0,0,0,4.42,2.32,26.86,26.86,0,0,0,4.88,1.08,20.63,20.63,0,0,0,2.48.16c.41,0,.81-.05,1.21-.08a6.16,6.16,0,0,0,1.13-.28,2.47,2.47,0,0,0,1.58-1.37,3.85,3.85,0,0,0,.09-2.24,16.26,16.26,0,0,0-1.9-4.56,45.23,45.23,0,0,0-2.78-4.23,96.74,96.74,0,0,0-6.49-7.84,23,23,0,0,1-3.2-4.07,45.13,45.13,0,0,1-2.4-4.57,163.22,163.22,0,0,1-7.07-19.23c-2-6.53-3.78-13.11-5.46-19.72,1.84,6.57,3.78,13.11,5.93,19.57a164.06,164.06,0,0,0,7.3,19.05,44.35,44.35,0,0,0,2.42,4.45,22.34,22.34,0,0,0,3.13,3.93,97.59,97.59,0,0,1,6.59,7.88,46.11,46.11,0,0,1,2.86,4.3,17.11,17.11,0,0,1,2,4.84,4.78,4.78,0,0,1-.14,2.79,3.45,3.45,0,0,1-2.13,1.91,7.11,7.11,0,0,1-1.34.34c-.44,0-.89.09-1.34.09a20.86,20.86,0,0,1-2.62-.16,27.69,27.69,0,0,1-5.09-1.16,23.85,23.85,0,0,1-4.61-2.44,50.43,50.43,0,0,1-7.79-6.75,130.5,130.5,0,0,1-12.83-16c-3.89-5.62-7.54-11.39-11-17.26S141.54,370.26,138.39,364.21Z"
      />
      <path
        fill="#cc0515"
        d="M273.7,52.18c-69.92-18.47-75.11,61.18-75.11,61.18l65.89,14.24c1.59-33.06,22.62-30.21,22.62-30.21,25-.07,18.39,44.2,18.39,44.2l7.22,1.91C333,66.77,273.7,52.18,273.7,52.18Z"
      />
      <path
        fill="#fff"
        d="M272.4,154.59c-.58,2.87-3.89,4.63-7.4,3.93l-81.1-16.27c-3.51-.7-5.89-3.6-5.31-6.48l4.27-21.28c.58-2.87,3.89-4.63,7.4-3.93l81.1,16.27c3.51.7,5.89,3.6,5.31,6.48Z"
      />
      <circle
        fill="#fff"
        cx="308.14"
        cy="144.22"
        r="11.75"
        transform="translate(106.13 417.97) rotate(-78.66)"
      />
      <path
        fill="#212121"
        d="M159,198a102.74,102.74,0,0,0,13.71,14,133.64,133.64,0,0,0,51.59,27.28A141.17,141.17,0,0,0,263,244.18a171.61,171.61,0,0,0,38.8-5,.13.13,0,0,1,.16.1.14.14,0,0,1,0,.13c-5.85,5.58-12.05,10.77-18.42,15.74s-12.94,9.68-19.71,14.1a234.48,234.48,0,0,1-42.88,22.44c-15,5.84-30.95,9.86-47.14,10.52q-6.06.22-12.13-.15a114.25,114.25,0,0,1-12-1.51,107.42,107.42,0,0,1-11.77-2.93,110.17,110.17,0,0,1-11.33-4.32l.25-.29c2.59,4,5,8,7.59,11.93a135.63,135.63,0,0,0,8.39,11.34,103.56,103.56,0,0,0,20.6,19.17,93.36,93.36,0,0,0,25.21,12.46,109.48,109.48,0,0,0,27.72,4.95,146.61,146.61,0,0,0,28.17-1.45c4.67-.64,9.29-1.57,13.89-2.55s9.16-2.19,13.68-3.54a316.67,316.67,0,0,0,52.4-21,316.74,316.74,0,0,1-52.38,21.1c-4.51,1.36-9.09,2.49-13.68,3.56s-9.23,1.93-13.9,2.57a146.77,146.77,0,0,1-28.2,1.5,109.7,109.7,0,0,1-27.79-4.91,93.64,93.64,0,0,1-25.3-12.45,103.88,103.88,0,0,1-20.7-19.19,136,136,0,0,1-8.43-11.36c-2.64-3.9-5-8-7.61-11.91a.2.2,0,0,1,.05-.28.21.21,0,0,1,.19,0,102.86,102.86,0,0,0,47.09,8.79c16.12-.7,32-4.63,47-10.45a234.09,234.09,0,0,0,42.85-22.33q10.14-6.61,19.71-14c6.36-5,12.57-10.13,18.42-15.69l.13.23A171.85,171.85,0,0,1,263,244.38a141.34,141.34,0,0,1-38.81-5A133.71,133.71,0,0,1,172.64,212,102.76,102.76,0,0,1,159,198Z"
      />
      <line
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="169"
        y1="210.16"
        x2="169"
        y2="217.45"
      />
      <ellipse fill="#f7f789" cx="169" cy="229.59" rx="4.86" ry="12.14" />
      <line
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="212.71"
        y1="234.45"
        x2="212.71"
        y2="241.73"
      />
      <ellipse fill="#cc0515" cx="212.71" cy="253.88" rx="4.86" ry="12.14" />
      <line
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="266.15"
        y1="244.16"
        x2="266.15"
        y2="251.45"
      />
      <ellipse fill="#f7f789" cx="266.15" cy="263.59" rx="4.86" ry="12.14" />
      <line
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="152"
        y1="300.02"
        x2="152"
        y2="307.31"
      />
      <ellipse fill="#cc0515" cx="152" cy="319.45" rx="4.86" ry="12.14" />
      <line
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="190.86"
        y1="300.02"
        x2="190.86"
        y2="307.31"
      />
      <ellipse fill="#f7f789" cx="190.86" cy="319.45" rx="4.86" ry="12.14" />
      <line
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="290.43"
        y1="249.02"
        x2="290.43"
        y2="256.31"
      />
      <ellipse fill="#cc0515" cx="290.43" cy="268.45" rx="4.86" ry="12.14" />
      <line
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="246.72"
        y1="280.59"
        x2="246.72"
        y2="287.88"
      />
      <ellipse fill="#cc0515" cx="246.72" cy="300.02" rx="4.86" ry="12.14" />
      <line
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="261.29"
        y1="348.6"
        x2="261.29"
        y2="355.88"
      />
      <ellipse fill="#cc0515" cx="261.29" cy="368.03" rx="4.86" ry="12.14" />
      <line
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="205.43"
        y1="353.45"
        x2="205.43"
        y2="360.74"
      />
      <ellipse fill="#f7f789" cx="205.43" cy="372.88" rx="4.86" ry="12.14" />
      <line
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="314.72"
        y1="329.17"
        x2="314.72"
        y2="336.45"
      />
      <ellipse fill="#f7f789" cx="314.72" cy="348.6" rx="4.86" ry="12.14" />
    </svg>
  )
}

export default IconXmasCat
