import React from 'react'

const IconWeddingCat = (props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      className="wedding-cat"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width}
      height={props.height}
      viewBox="0 0 500 500"
      aria-labelledby="title"
    >
      >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="149.39"
          y1="430.65"
          x2="184.98"
          y2="430.65"
          gradientTransform="rotate(-5.89 -3043.957 300.726)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#665391" />
          <stop offset="1" stopColor="#4f4f4f" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-6887.82"
          y1="-295.74"
          x2="-6852.23"
          y2="-295.74"
          gradientTransform="matrix(-.99 -.1 -.1 .99 -6596.56 -310.78)"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <title id="title">Cat as a bride on the wedding day</title>
      <path
        fill="#e5e5e5"
        d="M213.3 61a76.21 76.21 0 0 0 21.78 4c13.1.62 29.66-6.9 48.5 14.29s43.77 60.18 35.24 143.71-3.52 66.24-3.52 66.24-37.44-9.16-45.64-12.78c0 0-2.08 13.74-5.65 14s-46.38-15.12-71.91-17.72-54 7.34-54 7.34 2.31-16.81 2.78-20.93-22.22-4.51-31.94-3.59c0 0 23.92-111.08 36.86-144.6S178 54.88 213.3 61z"
      />
      <path
        fill="#e5e5e5"
        d="M271.47 173.28s-20.08 107.18-22 108.65-32.76-4.8-32.76-4.8l2.19.85c21.35 5.39 42.66 12.73 45.14 12.52 3.58-.29 5.65-14 5.65-14l-13.61-6.29c-.43-1.28 4.31-29.17 12.38-52zM176.23 272.6l-4.37-2.73s-25.19 8.06-27.62 3.18c-1.25-2.5.84-28.27 3.17-52.74-2.69 13.18-4.73 23.88-5.38 27.33-.45 2.41-1 6.18-1.68 10.52a1.29 1.29 0 0 1 .48 1c-.47 4.12-2.78 20.93-2.78 20.93a162.93 162.93 0 0 1 38.18-7.49z"
      />
      <path
        className="cls-2"
        fill="#464477"
        d="M321.64 422.32a10.29 10.29 0 1 1 0-20.58h.47a21.32 21.32 0 0 0-1.41-7c-6-17.89 12.21-25.9 25.47-31.75 10.53-4.64 22.35-9.85 29.64-18.69-15.1-6.47-69.79-6.47-88.72-6.47-6.44 0-11.86 0-15.67-.09-4.77-.12-15.94-.39-15.94-10.93a10.3 10.3 0 0 1 13.59-9.81c3 .2 10.92.2 18 .2C353 317.2 388 320 396.3 335.55c2.9 5.42 2.38 11.8-1.42 17.5-10.31 15.47-27 22.82-40.41 28.74-4.74 2.09-11.66 5.14-14 7.19 3.38 10.67 2.84 19.14-1.58 25.27-3.75 5.2-9.89 8.07-17.25 8.07zm-45.82-97.8a10.31 10.31 0 0 1 .25 2.24 10.28 10.28 0 0 0-.25-2.24z"
      />
      <path
        className="cls-3"
        fill="#9e9e9e"
        d="M241.56 99.86s-81.35-24.76-92 46-31.8 113.14-42.41 137.93S93 393.44 185 411.13s148.56-53.06 141.48-77.82-21.22-92-21.22-92-3.57-141.45-63.7-141.45z"
      />
      <path
        className="cls-3"
        fill="#9e9e9e"
        d="M137.17 342.87s49.52 88.43 77.82 92 3.54-42.45 0-53.06-28.3-38.91-21.22-49.52"
      />
      <path
        className="cls-2"
        fill="#464477"
        d="M137.17 342.87c5.08 8.76 10.43 17.36 16 25.84s11.23 16.85 17.25 25a255 255 0 0 0 19.3 23.37 104.19 104.19 0 0 0 11.12 10.23 48.83 48.83 0 0 0 6.25 4.18 24.73 24.73 0 0 0 6.91 2.69 18.35 18.35 0 0 0 7.33 0 7.75 7.75 0 0 0 5.45-4.25 15.57 15.57 0 0 0 1.17-7.2 41.59 41.59 0 0 0-1-7.43c-2.25-9.83-6.39-19.12-10.28-28.46-.49-1.17-1-2.34-1.42-3.53s-.82-2.39-1.36-3.52a74.42 74.42 0 0 0-3.66-6.61c-2.63-4.32-5.41-8.55-8-12.89a109 109 0 0 1-7.23-13.42 32.78 32.78 0 0 1-2.26-7.25 14.75 14.75 0 0 1-.16-3.79 8.44 8.44 0 0 1 1.23-3.56 8.41 8.41 0 0 0-1.17 3.57 14.65 14.65 0 0 0 .22 3.77 32.6 32.6 0 0 0 2.36 7.18 108.67 108.67 0 0 0 7.29 13.21l8.16 12.81a75 75 0 0 1 3.76 6.64c.57 1.15 1 2.4 1.41 3.55s.95 2.34 1.44 3.5c2 4.65 4 9.29 5.85 14a107.54 107.54 0 0 1 4.61 14.53 42.49 42.49 0 0 1 1.09 7.59 16.47 16.47 0 0 1-1.25 7.65 8.55 8.55 0 0 1-2.48 3.11 9.34 9.34 0 0 1-3.6 1.67 19.22 19.22 0 0 1-7.75 0 25.69 25.69 0 0 1-7.19-2.81 49.77 49.77 0 0 1-6.36-4.28 105.08 105.08 0 0 1-11.2-10.27 240.76 240.76 0 0 1-19.25-23.54 521.78 521.78 0 0 1-32.58-51.28z"
      />
      <path
        className="cls-3"
        fill="#9e9e9e"
        d="M300.46 347.84s-49.52 88.43-77.82 92-3.54-42.45 0-53.06 28.3-38.91 21.22-49.52"
      />
      <path
        className="cls-2"
        fill="#464477"
        d="M300.46 347.84a521.78 521.78 0 0 1-32.59 51.27 240.75 240.75 0 0 1-19.25 23.54A105.09 105.09 0 0 1 237.43 433a49.78 49.78 0 0 1-6.36 4.28 25.69 25.69 0 0 1-7.19 2.81 19.22 19.22 0 0 1-7.75 0 9.34 9.34 0 0 1-3.6-1.67 8.55 8.55 0 0 1-2.53-3.12 16.47 16.47 0 0 1-1.25-7.65 42.49 42.49 0 0 1 1.09-7.59 107.54 107.54 0 0 1 4.61-14.53c1.81-4.74 3.85-9.39 5.85-14 .5-1.16 1-2.33 1.44-3.5s.84-2.4 1.41-3.55a75 75 0 0 1 3.76-6.64l8.21-12.84a108.67 108.67 0 0 0 7.33-13.26 32.6 32.6 0 0 0 2.36-7.18 14.66 14.66 0 0 0 .22-3.77 8.41 8.41 0 0 0-1.17-3.57 8.44 8.44 0 0 1 1.23 3.56 14.75 14.75 0 0 1-.16 3.79 32.78 32.78 0 0 1-2.26 7.25 109 109 0 0 1-7.14 13.42c-2.63 4.33-5.41 8.57-8 12.89a74.45 74.45 0 0 0-3.66 6.61c-.54 1.13-.9 2.31-1.36 3.52s-.94 2.36-1.42 3.53c-3.89 9.34-8 18.63-10.28 28.46a41.6 41.6 0 0 0-1 7.43 15.57 15.57 0 0 0 1.17 7.2 7.75 7.75 0 0 0 5.45 4.25 18.35 18.35 0 0 0 7.33 0 24.73 24.73 0 0 0 6.91-2.69 48.83 48.83 0 0 0 6.25-4.18A104.18 104.18 0 0 0 248 422a255 255 0 0 0 19.3-23.37c6-8.12 11.74-16.49 17.25-25s10.83-17.03 15.91-25.79z"
      />
      <path
        className="cls-4"
        fill="#f49595"
        d="M181.62 101.77s-2.18 4.32-5.52 9.68c-5.31 8.59-13.56 19.85-20.41 20.28-11.15.7-16.19-39.19-11-41.52s36.93 11.56 36.93 11.56z"
      />
      <path
        d="M181.62 101.77s-2.18 4.32-5.52 9.68c-6.88-7.5-20.23-18.3-31.45-21.23 5.15-2.33 36.97 11.55 36.97 11.55z"
        fill="url(#linear-gradient)"
      />
      <path
        className="cls-4"
        fill="#f49595"
        d="M249.49 101.77s2.18 4.32 5.52 9.68c5.31 8.59 13.56 19.85 20.41 20.28 11.15.7 16.19-39.19 11-41.52s-36.93 11.56-36.93 11.56z"
      />
      <path
        d="M249.49 101.77s2.18 4.32 5.52 9.68c6.88-7.5 20.23-18.3 31.45-21.23-5.15-2.33-36.97 11.55-36.97 11.55z"
        fill="url(#linear-gradient-2)"
      />
      <path
        className="cls-7"
        fill="#f9e8d5"
        d="M310.53 205.8c2.21-1.33 4-5.86 0-7.29s-4.42 6.08-4.42 6.08c-.88-4.42-4-8.29-7.29-4.64s3.2 6.85 3.2 6.85c-3.32.22-5.41 1.77-4.53 4.31 1.28 3.67 6.85.33 6.85.33-.33 2.77 0 6.85 4.42 7.07s1-7 1-7c2.54 1.11 6.19 1.77 6.41-2.65s-5.64-3.06-5.64-3.06zm-3.7 4a1.6 1.6 0 1 1 1.6-1.6 1.6 1.6 0 0 1-1.6 1.58zM309 250.35c1.89 1 5.87.36 5.21-3.09s-6.22-.58-6.22-.58c2.78-2.51 4.23-6.36.23-7.18s-3.54 5.2-3.54 5.2c-1.57-2.27-3.56-3.12-5-1.41-2.08 2.47 2.67 5 2.67 5-2.11.93-4.89 2.9-3.17 6.15s5.4-2.23 5.4-2.23c.29 2.29 1.36 5.17 4.61 3.45s-.19-5.31-.19-5.31zm-4.41-1a1.33 1.33 0 1 1 1.82.46 1.33 1.33 0 0 1-1.8-.42z"
      />
      <path
        className="cls-8"
        fill="#f9d8b8"
        d="M294.29 230.56c1.55.15 4-1.39 2.63-3.52s-4.32 1.34-4.32 1.34c1.17-2.45 1.07-5.43-1.85-4.86s-.92 4.45-.92 4.45c-1.68-1.08-3.25-1.1-3.74.44-.71 2.23 3.19 2.64 3.19 2.64-1.16 1.21-2.47 3.3-.42 5s3-3 3-3c.82 1.45 2.34 3.07 4 1s-1.57-3.49-1.57-3.49zm-3.22.57a1 1 0 0 1-.19-1.35 1 1 0 1 1 .19 1.35z"
      />
      <path
        className="cls-7"
        fill="#f9e8d5"
        d="M123.93 233.49c1.89 1 5.87.36 5.21-3.09s-6.22-.58-6.22-.58c2.78-2.51 4.23-6.36.22-7.19s-3.54 5.2-3.54 5.2c-1.57-2.27-3.56-3.12-5-1.41-2.08 2.47 2.67 5 2.67 5-2.11.93-4.89 2.9-3.17 6.15s5.4-2.23 5.4-2.23c.29 2.29 1.36 5.17 4.61 3.45s-.18-5.3-.18-5.3zm-4.41-1a1.33 1.33 0 1 1 1.82.46 1.33 1.33 0 0 1-1.82-.42z"
      />
      <path
        className="cls-8"
        fill="#f9d8b8"
        d="M132.1 211c1.49-.45 3.19-2.83 1.09-4.25s-3.49 2.88-3.49 2.88c.15-2.71-1.08-5.42-3.56-3.79s.85 4.46.85 4.46c-2-.37-3.43.23-3.29 1.84.2 2.32 4 1.22 4 1.22-.61 1.56-1 4 1.51 4.78s1.62-3.91 1.62-3.91c1.31 1 3.34 1.95 4.13-.59S132.1 211 132.1 211zm-2.75 1.75a.94.94 0 0 1-.69-1.17 1 1 0 1 1 1.86.48 1 1 0 0 1-1.18.65z"
      />
      <path
        d="M176.4 83.24s-17.4 5 19.88 32.31 101.89 39.76 101.89 39.76a104.66 104.66 0 0 0-107.92-73.14z"
        fill="#f3f4f4"
        opacity=".2"
      />
      <path
        className="cls-7"
        fill="#f9e8d5"
        d="M238.54 106.81c2-3.9.48-12-6.54-10.52s-.92 12.74-.92 12.74c-5.25-5.59-13.18-8.39-14.68-.15s10.77 7 10.77 7c-4.59 3.3-6.23 7.41-2.66 10.29 5.13 4.15 10.18-5.68 10.18-5.68 2 4.28 6.15 9.88 12.72 6.23s-4.82-10.93-4.82-10.93c4.67-.69 10.52-3 6.87-9.58s-10.92.6-10.92.6zm-1.76 9.07a2.73 2.73 0 1 1 .87-3.75 2.73 2.73 0 0 1-.87 3.75z"
      />
      <path
        className="cls-10"
        fill="#fbbb9b"
        d="M260.11 125.61c1.35 1.92 5.6 3.23 6.65-.43s-5.85-3.63-5.85-3.63c4-1.06 7.28-4.07 3.73-6.84s-6 3.27-6 3.27c-.43-3-2-4.78-4.24-3.83-3.25 1.37.17 6.2.17 6.2-2.54-.14-6.24.39-6.14 4.39s6.4.5 6.4.5c-.82 2.36-1.18 5.69 2.86 5.63s2.42-5.26 2.42-5.26zm-3.88-3.1a1.44 1.44 0 0 1 1.35-1.55 1.49 1.49 0 0 1 1.57 1.36 1.43 1.43 0 0 1-1.35 1.53 1.47 1.47 0 0 1-1.57-1.34z"
      />
      <path
        className="cls-7"
        fill="#f9e8d5"
        d="M191.87 105.11c1.12 1 3.91 1.07 4-1.31s-4.12-1.27-4.12-1.27c2.23-1.27 3.76-3.62 1.17-4.74s-3.13 2.94-3.13 2.94c-.74-1.73-2-2.58-3.19-1.65-1.75 1.35 1.09 3.74 1.09 3.74-1.56.32-3.71 1.23-3 3.64s4-.72 4-.72c-.12 1.56.19 3.63 2.63 3s.55-3.63.55-3.63zm-2.87-1.26a.9.9 0 0 1 .57-1.15.93.93 0 0 1 1.17.58.89.89 0 0 1-.57 1.14.93.93 0 0 1-1.17-.57zM270.53 138.13c1.32.66 4.05.12 3.54-2.21s-4.3-.26-4.3-.26c1.86-1.76 2.8-4.41 0-4.89s-2.35 3.6-2.35 3.6c-1.12-1.52-2.51-2.05-3.48-.86-1.38 1.72 1.94 3.37 1.94 3.37-1.44.68-3.32 2.07-2.08 4.25s3.68-1.62 3.68-1.62c.24 1.54 1 3.47 3.25 2.25s-.2-3.63-.2-3.63zm-3.06-.56a.9.9 0 0 1 .29-1.25.92.92 0 0 1 1.27.28.89.89 0 0 1-.29 1.24.92.92 0 0 1-1.27-.27z"
      />
      <path
        className="cls-10"
        fill="#fbbb9b"
        d="M210.46 105.31c2.35.16 6.05-2.3 3.87-5.42s-6.51 2.25-6.51 2.25c1.69-3.74 1.44-8.21-3-7.21s-1.26 6.74-1.26 6.74c-2.58-1.54-5-1.48-5.65.87-1 3.38 4.92 3.78 4.92 3.78-1.71 1.88-3.63 5.09-.47 7.53s4.42-4.66 4.42-4.66c1.31 2.14 3.66 4.5 6.17 1.34s-2.49-5.22-2.49-5.22zm-4.86 1.05a1.44 1.44 0 0 1-.34-2 1.49 1.49 0 0 1 2-.36 1.45 1.45 0 0 1 .34 2 1.49 1.49 0 0 1-2 .36z"
      />
      <circle
        className="cls-11"
        fill="#e5ea32"
        cx="196.28"
        cy="172.7"
        r="13.67"
      />
      <path
        className="cls-12"
        d="M221.39 198.14l5.26-4.9a.38.38 0 0 0-.26-.65h-10.51a.38.38 0 0 0-.26.65l5.26 4.9a.38.38 0 0 0 .51 0zM236 193.69a26.4 26.4 0 0 1 6.72-.57 29.24 29.24 0 0 1 6.71 1 23.84 23.84 0 0 1 6.25 2.67 21.15 21.15 0 0 1 5.17 4.35c-.45-.34-.9-.67-1.33-1l-.66-.51c-.23-.16-.47-.3-.7-.45l-1.38-.92-1.46-.78a30.61 30.61 0 0 0-6.14-2.37 40.82 40.82 0 0 0-6.5-1.21c-1.1-.11-2.21-.19-3.32-.21s-2.19-.05-3.36 0zM236 199.21a26.77 26.77 0 0 1 11.27 2.37 21.88 21.88 0 0 1 4.94 3.1 16.35 16.35 0 0 1 3.67 4.47 25.6 25.6 0 0 0-4.16-3.86 29.15 29.15 0 0 0-4.87-2.8 42 42 0 0 0-5.3-2 54.31 54.31 0 0 0-5.55-1.28zM206.22 193.69c-1.12 0-2.24-.08-3.35 0s-2.22.1-3.32.21a40.81 40.81 0 0 0-6.5 1.21 30.61 30.61 0 0 0-6.14 2.37l-1.46.78-1.38.92c-.23.15-.48.29-.7.45l-.66.51c-.44.35-.88.69-1.34 1a21.15 21.15 0 0 1 5.17-4.35 23.84 23.84 0 0 1 6.25-2.67 29.24 29.24 0 0 1 6.71-1 26.4 26.4 0 0 1 6.72.57zM206.22 199.21a54.32 54.32 0 0 0-5.55 1.33 42 42 0 0 0-5.3 2 29.15 29.15 0 0 0-4.87 2.8 25.59 25.59 0 0 0-4.16 3.86 16.34 16.34 0 0 1 3.67-4.47 21.88 21.88 0 0 1 4.94-3.1 26.77 26.77 0 0 1 11.27-2.42z"
      />
      <circle
        className="cls-12"
        fill="#070707"
        cx="196.28"
        cy="172.7"
        r="9.32"
      />
      <circle
        className="cls-11"
        fill="#e5ea32"
        cx="243.5"
        cy="172.7"
        r="13.67"
      />
      <circle
        className="cls-12"
        fill="#070707"
        cx="243.5"
        cy="172.7"
        r="9.32"
      />
      <path
        className="cls-12"
        fill="#070707"
        d="M221.14 195.69a5.89 5.89 0 0 1-1.54 4.67 6.35 6.35 0 0 1-9.34 0 5.88 5.88 0 0 1-1.54-4.67 6.66 6.66 0 0 0 2.1 4.12 6.11 6.11 0 0 0 8.24 0 6.65 6.65 0 0 0 2.08-4.12z"
      />
      <path
        className="cls-12"
        fill="#070707"
        d="M233.56 195.69a5.89 5.89 0 0 1-1.54 4.67 6.35 6.35 0 0 1-9.34 0 5.88 5.88 0 0 1-1.54-4.67 6.66 6.66 0 0 0 2.1 4.12 6.11 6.11 0 0 0 8.24 0 6.65 6.65 0 0 0 2.08-4.12z"
      />
    </svg>
  )
}

export default IconWeddingCat
